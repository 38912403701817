<template>
    <main-page>
        <b-container class="text-center">
            <vue-slot-machine></vue-slot-machine>
        </b-container>
    </main-page>
</template>

<script>
import MainPage from "@/components/main-page";
import VueSlotMachine from "@/components/vue-slot-machine";

export default {
    name: "slot-machine",
    components: {VueSlotMachine, MainPage},
    data() {
        return {
            list: [
                {text: '1', color: '#668CFF'},
                {text: '2', color: '#FF6666'},
                {text: '3', color: '#B366FF'},
            ],
            trigger: null,
        }
    },
    methods: {
        start() { // Trigger to let the machine start
            this.trigger = new Date();
        },
        onComplete(data) { // Run complete callback
            console.log(data);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
