<template>
    <div class="slot-wheel" :class="{'is-locked':locked}" @click="lock()">
        <div class="slot-wheel-inner">
            <img class="slot-wheel-image" :src="reelTileData[tile1Index].image"/>
            <img class="slot-wheel-image" :src="reelTileData[tile2Index].image"/>
            <img class="slot-wheel-image" :src="reelTileData[tile3Index].image"/>
            <img class="slot-wheel-image" :src="reelTileData[tile4Index].image"/>
            <img class="slot-wheel-image" :src="reelTileData[tile5Index].image"/>
        </div>
    </div>
</template>

<script>
export default {
    name: `vue-slot-wheel`,
    props: ['value', 'canlock'],
    data: function () {
        return {
            momentum: null,
            audio: {
                spin: new Audio('https://freesound.org/data/previews/120/120373_824230-lq.mp3'),
                spinEnd: new Audio('https://freesound.org/data/previews/145/145441_2615119-lq.mp3'),
                lock: new Audio('https://freesound.org/data/previews/56/56246_91374-lq.mp3')
            },
            reelTileCount: 15,
            reelTileData: null,
            reelSourceData: [
                {
                    name: 'Truffle',
                    value: 2,
                    image:  require('../assets/img/truffle.png')
                },
                {
                    name: 'Cocktail',
                    value: 10,
                    image:  require('../assets/img/cocktail-glass.png')
                },
                // {
                //     name: 'Fer à cheval',
                //     value: 10,
                //     image: require('../assets/img/horse.png')
                // },
                {
                    name: 'Jackpot',
                    value: 1000,
                    image: require('../assets/img/jackpot.png')
                }
            ],
            reelIndex: 2,
            tile1Index: 0,
            tile2Index: 1,
            tile3Index: 2,
            tile4Index: 3,
            tile5Index: 4,
            locked: false
        }
    },
    beforeMount: function () {
        // Build up the reelTileData array with random tiles
        let frs = []
        // let count = this.reelTileCount
        this.audio.spin.volume = 0.3
        this.audio.spinEnd.volume = 0.8
        this.audio.lock.volume = 0.2
        this.audio.spin.currentTime = 0.3

        // Method 2, sort on value, use index to determine entry count, shuffle
        let reelSourceData = this.reelSourceData.slice(0)
        reelSourceData.sort((a, b) => b.value - a.value)
        reelSourceData.forEach((sd, i) => {
            let times = i + 1 + i // 0+1+0=1, 3+2+3=8
            while (times > 0) {
                frs.push(sd)
                times--
            }
        })

        function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

// While there remain elements to shuffle...
            while (0 !== currentIndex) {

// Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

// And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        this.reelTileData = shuffle(frs)
    },
    mounted: function () {
        this.$el.addEventListener("transitionend", this.animateEnd)
    },
    computed: {

    },
    methods: {
        run: function () {
            console.log('run')
            if (!this.locked) {
                const min = 8
                const max = 28
                const momentum = Math.floor(Math.random() * (max - min + 1) + min);
                this.momentum = momentum
                this.audio.spin.play()
                this.animate()
            } else {
                this.locked = false
                this.$emit('stopped', this.reelTileData[this.reelIndex], true)
            }
        }
        ,
        animate: function () {
            this.$el.classList.add('move')
        }
        ,
        animateEnd: function () {
            this.$el.classList.remove('move')
            this.reIndex()
            this.momentum = this.momentum - 1
            if (this.momentum > 0) {
                setTimeout(this.animate, 10)
            } else {
                this.$emit('stopped', this.reelTileData[this.reelIndex])
                this.audio.spinEnd.play()
                this.audio.spin.pause()
                this.audio.spin.currentTime = 0.3
            }
        }
        ,
        reIndex: function () {
            const end = this.reelTileData.length - 1 //this.reelTileCount - 1
            const index = this.reelIndex === 0 ? end : this.reelIndex - 1
            // const index = this.index === end ? 0 : this.index + 1
            this.reelIndex = index
            this.tile1Index = index === 1 ? end : index === 0 ? end - 1 : index - 2
            this.tile2Index = index === 0 ? end : index - 1
            this.tile3Index = index
            this.tile4Index = index === end ? 0 : index + 1
            this.tile5Index = index === end - 1 ? 0 : index === end ? 1 : index + 2
        }
        ,
        lock: function () {
            if (this.canlock) {
                this.locked = !this.locked
                this.audio.lock.play()
            }
        }
    }
}
</script>

<style lang="scss">
.slot-wheel {
    flex: 1;
    min-width: calc(var(--tileSize) + 30px);
    max-width: calc(var(--tileSize) + 30px);
    height: calc(var(--tileSize) * 2.2);
    overflow: hidden;

    //&:not(last-child) {
    //     margin-right: 100px;
    // }
}

.slot-wheel.is-locked {
    /*   opacity: 0.8; */
    background: rgba(0, 0, 0, 0.1);
}

.slot-wheel:not(:last-child) {
    border-right: 1px solid gray(0, 0.1);
}

.slot-wheel-inner {
    position: relative;
    top: calc(var(--tileSize) * -1 * 1.333);
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.slot-wheel.move .slot-wheel-inner {
    transition: margin-top 0.04s linear;
    margin-top: var(--tileSize);
}

.slot-wheel-image {
    width: var(--tileSize);
    height: var(--tileSize);
    padding: 15px;
}
</style>