<template>
    <div class="slot-machine">
        <b-img class="background" :src="require('../assets/img/slot-machine-trigger-up.svg')"></b-img>
        <b-img class="background" :src="require('../assets/img/slot-machine-background.svg')"></b-img>
        <div @click="spin()" class="trigger"></div>

        <div class="slot-machine-reels">
            <div class="slot-machine-shadow"></div>
            <div class="slot-machine-payline"></div>
            <vue-slot-wheel ref="reel1" :canlock="canlock" @stopped="reelStopped"></vue-slot-wheel>
            <vue-slot-wheel ref="reel2" style="margin-left: 54px" :canlock="canlock" @stopped="reelStopped"></vue-slot-wheel>
            <vue-slot-wheel ref="reel3" style="margin-left: 54px" :canlock="canlock" @stopped="reelStopped"></vue-slot-wheel>
        </div>

        <b-img class="front" :src="require('../assets/img/slot-machine-front.png')"></b-img>

    </div>
</template>

<script>
import VueSlotWheel from "@/components/vue-slot-wheel";

export default {
    name: 'vue-slot-machine',
    components: {VueSlotWheel},
    data: function () {
        return {
            spend: 1,
            credits: 6000,
            win: 0,
            resultData: false,
            canlock: true,
            waslocked: false,
            audio: {
                win: new Audio('https://freesound.org/data/previews/387/387232_1474204-lq.mp3'),
                insertCoin: new Audio('https://freesound.org/data/previews/276/276091_5123851-lq.mp3'),
                bigwin: new Audio('https://freesound.org/data/previews/270/270319_5123851-lq.mp3')
            }
        }
    },
    beforeMount: function () {
    },
    mounted: function () {
        window.addEventListener('keydown', this.keydown)
    },
    computed: {},
    methods: {
        keydown: function (e) {
            console.log(e.which)
            const key = {
                one: 49,
                two: 50,
                three: 51,
                space: 32
            }
            if (e.which === key.one) {
                this.$refs.reel1.lock()
                e.preventDefault()
            } else if (e.which === key.two) {
                this.$refs.reel2.lock()
                e.preventDefault()
            } else if (e.which === key.three) {
                this.$refs.reel3.lock()
                e.preventDefault()
            } else if (e.which === key.space) {
                this.spin()
                e.preventDefault()
            }
        },
        spin: function () {
            if (this.credits > 0 && !this.resultData) {
                this.resultData = []
                this.credits = this.credits - 0.5
                this.$refs.reel1.run()
                this.$refs.reel2.run()
                this.$refs.reel3.run()
            }
        },
        insertCoin: function () {
            this.audio.insertCoin.currentTime = 0
            this.audio.insertCoin.play()
            this.credits += .5
            this.spend += .5
        },
        takeWin: function () {
            if (this.win > 0) {
                this.credits += this.win
                this.win = 0
            }
        },
        reelStopped(resultData, wasLocked) {
            if (wasLocked) this.waslocked = wasLocked

            this.resultData.push(resultData)
            if (this.resultData.length === 3) {
                this.checkWin(this.resultData)
                if (this.waslocked) {
                    this.waslocked = false
                    this.canlock = false
                } else {
                    this.canlock = true
                }
            }
        },
        checkWin: function () {
            if (this.resultData.length === 3) { // ;-)
                const v1 = this.resultData[0]
                const v2 = this.resultData[1]
                const v3 = this.resultData[2]
                if (v1.name === v2.name && v2.name === v3.name) {
                    if (v1.value >= 10) {
                        this.audio.bigwin.play()
                    } else {
                        this.audio.win.play()
                    }
                    this.win += v1.value
                    this.waslocked = true // prevent lock after an unlocked win
                } else {
                    const bar1 = v1.name === 'Bar'
                    const bar2 = v2.name === 'Bar'
                    const bar3 = v3.name === 'Bar'
                    if (bar1 && bar2 || bar1 && bar3 || bar2 && bar3) {
                        // this.audio.bigwin.play()
                        // this.win += 16
                        // this.waslocked = true // prevent lock after an unlocked win
                    } else if (bar1 || bar2 || bar3) {
                        // this.audio.win.play()
                        // this.win += 4
                        // this.waslocked = true // prevent lock after an unlocked win
                    } else {
                        // Lose : (
                    }
                }
            }
            this.resultData = false
        },
    }
}
</script>

<style lang="scss">
:root {
    --tileSize: 140px;
}

.slot-machine {
    border-radius: 5px;
    position: relative;
    height: 800px;
    padding: 330px 245px 100px;

    .front,
    .background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        object-fit: contain;
    }

    .trigger {
        position: absolute;
        right: -10px;
        top: 44px;
        width: 230px;
        height: 230px;
        border-radius: 50%;

        &:hover {
            background: rgba(white, 0.2);
        }
        &:active {
            background: rgba(white, 0.4);
        }
    }
}
.slot-machine-reels {
    display: flex;
    position: relative;
}
.slot-machine-shadow {
    border-radius: 4px 4px 0 0;
    pointer-events: none;
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow:
        inset 0 25px 30px -5px gray(0,.1),
        inset 0 5px 10px -2px gray(0,.2),
        inset 0 -25px 30px -5px gray(0,.1),
        inset 0 -5px 10px -2px gray(0,.2);
}
.slot-machine-payline {
    position: absolute;
    top: calc(var(--tileSize) * 1 * 1.1666);
    height: 1px;
    width: 100%;
    background: gray(0, 0.3);
}


.slot-machine-stats {
    display: flex;
    background: #000;
    justify-content: flex-end;
    padding: 10px 0;
}
.slot-machine-coin {
    width: 6px;
    height: 38px;
    background: gray(20);
    border: 3px solid gray(40);
    border-radius: 6px;
    margin: 12px 10px 0 10px;
}
.slot-machine-coin:hover {
    background: gray(140);
}
.slot-machine-stat {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex-grow: 1;
}
.slot-machine-statTitle {
    font-size: 12px;
    color: gray(60);
}
.slot-machine-statValue {
    padding: 5px 10px;
    background: rgba(255,0,0,0.15);
    border-radius: 2px;
    border: 1px solid gray(0);
    font-size: 25px;
    text-align: right;
    color: rgba(255, 0, 0, 0.8);
    text-shadow: 0 0 4px rgba(255, 0, 0, 0.5);
}
.slot-machine-statSub {
    font-size: 9px;
    color: gray(50);
    text-align: right;
}

.slot-machine-actions {
    padding: 20px 10px;
    display: flex;
    justify-content: flex-end;
    background: gray(20);
    border-radius: 0 0 4px 4px;
}
.slot-machine-button {
    font-size: 17px;
    font-weight: bold;
    padding: 14px 12px;
    margin-left: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 2px gray(0);
}
.slot-machine-button:focus {
    outline: none;
}
.slot-machine-button:active {
    box-shadow: 0 0 2px 1px gray(0);
}

.slot-machine-button.is-spin {
    background: rgba(0, 255, 0, 0.4);
    border: 1px solid rgba(0, 255, 0, 0.4);
}
.slot-machine-button.is-spin:hover {
    background: rgba(0, 255, 0, 0.43);
}
.slot-machine-button.is-spin:active {
    background: rgba(0, 255, 0, 0.46);
}

.slot-machine-button.is-win {
    background: rgba(255, 0, 0, 0.4);
    border: 1px solid rgba(255, 0, 0, 0.5);
}
.slot-machine-button.is-win.has-win {
    background: rgba(255, 0, 0, 0.99);
}
.slot-machine-button.is-win:hover {
    background: rgba(255, 0, 0, 0.65);
}
.slot-machine-button.is-win:active {
    background: rgba(255, 0, 0, 0.7);
}
</style>